<template>
  <v-dialog
    v-model="show"
    v-if="processing > 0"
    persistent
    content
    content-class="centered-dialog"
  >
    <v-container class="ma-0 pa-0 fill-height" fluid>
      <v-row justify="center" align="center">
        <v-col cols="4" md="1">
          <loader />
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: "ProgressBarCircular",
  data() {
    return {
      //show: true,
    };
  },
  props: {
    width: {
      type: Number,
      default: 8,
    },
    size: {
      type: Number,
      default: 120,
    },
  },

  components: {
    Loader: () => import("./icons/Loader"),
  },
  computed: {
    processing() {
      return this.$store.state.app.processing;
    },
    show() {
      return true;
    },
  },
};
</script>

<style>
.dialog.centered-dialog,
.v-dialog.centered-dialog {
  background: transparent;
  box-shadow: none;
  border-radius: 6px;
  height: 100%;
  width: 100%;
  overflow: hidden color whitesmoke;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
</style>
